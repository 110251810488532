<template>
    <div>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span>报名信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择类型"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6">
                        <el-form-item label="赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.districtId"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="报名状态" prop="status">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.status"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_examine"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校" prop="schoolName">
                            <el-input
                                placeholder="请输入"
                                clearable
                                v-model="queryForm.schoolName"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="俱乐部" prop="clubName">
                            <el-input
                                placeholder="请输入"
                                clearable
                                v-model="queryForm.clubName"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="组别" prop="groupLevel">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.groupLevel"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_schoolGroup"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getData('search')"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" style="width: 100%" @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span>报名信息列表</span>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-button
                        type="primary"
                        plain
                        icon="el-icon-download"
                        @click="exportTable()"
                        >批量导出</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column
                    label="注册时间"
                    prop="createTime"
                ></el-table-column>
                <el-table-column label="学校名称" prop="schoolName">
                    <template slot-scope="scope">
                        <el-link
                            v-if="
                                scope.row.itsoRegisterJoin &&
                                scope.row.itsoRegisterJoin.clubId &&
                                scope.row.schoolName
                            "
                            type="primary"
                            @click="
                                goClubDetail(scope.row.itsoRegisterJoin.clubId)
                            "
                            >{{ scope.row.schoolName }}</el-link
                        >
                        <span v-else>{{ scope.row.schoolName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="赛区" prop="districtName">
                    <template slot-scope="scope">
                        <span v-if="scope.row.districtName">{{
                            scope.row.districtName
                        }}</span>
                        <span v-else style="color: red">未匹配</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="学校组别"
                    prop="groupLevel"
                    :formatter="groupLevelFrmatter"
                ></el-table-column>
                <el-table-column
                    label="学校地区"
                    prop="schoolAreaNames"
                ></el-table-column>
                <el-table-column label="俱乐部">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.clubName && scope.row.clubName !== ""
                                ? scope.row.clubName
                                : "-"
                        }}</span>
                    </template></el-table-column
                >
                <el-table-column
                    label="指导教师信息"
                    prop="teacherName1"
                    width="100px"
                >
                    <template slot-scope="scope">
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <span>{{
                                scope.row.teacherName1 &&
                                scope.row.teacherName1 !== ""
                                    ? scope.row.teacherName1
                                    : "-"
                            }}</span>
                            <span>{{
                                scope.row.teacherPhone1 &&
                                scope.row.teacherPhone1 !== ""
                                    ? scope.row.teacherPhone1
                                    : "-"
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
          label="指导教师1电话"
          prop="teacherPhone1"
        ></el-table-column> -->

                <!-- <el-table-column label="俱乐部名称" prop="clubName"></el-table-column> -->
                <!-- <el-table-column
          label="指导教师2姓名"
          prop="teacherName2"
        ></el-table-column>
        <el-table-column
          label="指导教师2电话"
          prop="teacherPhone2"
        ></el-table-column> -->
                <el-table-column
                    label="参赛队数量"
                    prop="teamNum"
                ></el-table-column>

                <el-table-column label="线下缴费回执" prop="payImageUrl">
                    <template slot-scope="scope" v-if="scope.row.payImageUrl">
                        <el-image
                            v-if="scope.row.payImageUrl"
                            style="width: 30px; height: 30px"
                            :src="scope.row.payImageUrl"
                            fit="contain"
                            :preview-src-list="[scope.row.payImageUrl]"
                        ></el-image>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="payNote"></el-table-column>
                <el-table-column label="缴费信息">
                    <template slot-scope="scope">
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <el-tag
                                type="success"
                                v-if="scope.row.status === 3"
                                size="mini"
                                >已缴费</el-tag
                            >
                            <el-tag
                                type="danger"
                                v-else-if="scope.row.status === 2"
                                size="mini"
                                >已驳回</el-tag
                            >
                            <el-tag
                                type="danger"
                                v-else-if="scope.row.status === 4"
                                size="mini"
                                >已退费</el-tag
                            >
                            <el-tag
                                type="warning"
                                v-else-if="scope.row.status === 1"
                                size="mini"
                                >待缴费</el-tag
                            >
                            <el-tag
                                type="info"
                                v-else-if="scope.row.status === 0"
                                size="mini"
                                >待审核</el-tag
                            >
                            <span v-else>-</span>
                            <el-tag
                                type="success"
                                style="margin-top: 10px"
                                v-if="scope.row.itsoRegisterJoin"
                                size="mini"
                                >已匹配</el-tag
                            >
                            <el-tag
                                type="danger"
                                v-else
                                size="mini"
                                style="margin-top: 10px"
                                >未匹配</el-tag
                            >
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="缴费时间" prop="orderNoPay" fixed="right">
          <template slot-scope="scope">
            <div v-if="scope.row.orderNoPay">{{scope.row.orderNoPay.endTime}}</div>
            <div v-else>-</div>
          </template>
        </el-table-column> -->
                <!-- <el-table-column label="是否匹配" fixed="right">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.itsoRegisterJoin"
                            size="mini"
                            >已匹配</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未匹配</el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作" width="150px" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            v-if="
                                scope.row.status === 2 || scope.row.status === 0
                            "
                            @click="showCheckDialog(scope.row)"
                            >审核</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.status === 1"
                            @click="showCheckDialog(scope.row, 'turnDown')"
                            >驳回</el-button
                        >
                        <el-button
                            v-if="
                                scope.row.status === 3 &&
                                scope.row.itsoRegisterJoin
                            "
                            type="text"
                            size="small"
                            @click="showUnbindDialog(scope.row)"
                            >解绑</el-button
                        >
                        <el-button
                            v-if="scope.row.status === 1"
                            type="text"
                            size="small"
                            style="color: orange"
                            @click="confirmationofPayment(scope.row)"
                            >确认缴费</el-button
                        >
                        <el-button
                            v-if="scope.row.status === 3"
                            type="text"
                            style="color: green"
                            size="small"
                            >已缴费</el-button
                        >
                        <el-button
                            v-if="
                                scope.row.status === 3 &&
                                !scope.row.itsoRegisterJoin
                            "
                            type="text"
                            size="small"
                            @click="showMatchDialog(scope.row)"
                            >匹配</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 报名信息审核弹窗 -->
        <el-dialog
            title="报名信息审核"
            :visible.sync="checkDialogVisible"
            width="30%"
            @close="checkDialogVisibleClose"
        >
            <el-form
                :model="checkForm"
                :rules="checkFormRules"
                ref="checkFormRef"
                label-width="80px"
            >
                <el-form-item label="报名审核" prop="status">
                    <el-select
                        v-model="checkForm.status"
                        placeholder="请选择"
                        style="width: 100%"
                    >
                        <el-option label="通过" value="1"></el-option>
                        <el-option label="驳回" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="驳回原因"
                    prop="rejected"
                    v-if="checkForm.status === '2'"
                >
                    <el-input v-model="checkForm.rejected"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="checkDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="setStatus()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 显示匹配弹窗 -->
        <el-dialog
            title="匹配学校"
            :visible.sync="matchDialogVisible"
            width="30%"
        >
            <el-form
                :model="matchForm"
                :rules="matchFormRules"
                ref="matchFormRef"
                label-width="80px"
            >
                <el-form-item label="省市区" prop="areaArray">
                    <el-cascader
                        placeholder="请选择"
                        v-model="matchForm.schoolArea"
                        ref="schoolAreaRef"
                        :options="areasData"
                        style="width: 100%"
                        :props="{
                            expandTrigger: 'hover',
                            label: 'name',
                            value: 'areaId',
                            children: 'areas',
                        }"
                        @change="provincesHandleChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="学校名称" prop="schoolId">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="matchForm.schoolId"
                        clearable
                        filterable
                    >
                        <el-option
                            v-for="item in queryFormSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="matchDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="setMatchData()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <!-- 显示缴费弹窗 -->
        <el-dialog
            title="确认缴费"
            :visible.sync="confirmationofPaymentDialogVisible"
            @close="confirmationofDialogClose"
            width="40%"
        >
            <el-form
                :model="confirForm"
                :rules="confirFormRules"
                ref="confirFormRef"
                label-width="80px"
            >
                <el-form-item label="缴费凭证" prop="payImageUrl">
                    <el-upload
                        :class="[hideUploadAdd == true ? 'hide' : '']"
                        ref="uploadCoverImgRef"
                        :action="upLoadPicUrl"
                        :limit="1"
                        list-type="picture-card"
                        style="margin-top: 10px"
                        accept=".jpg,.jpeg,.png,.bmp"
                        :file-list="imgFilesList"
                        :multiple="true"
                        :headers="headerObj"
                        :data="{
                            filePath: 'spbcnWeb/itso/',
                        }"
                        :on-success="uploadPicSuccess"
                        :before-upload="beforeAvatarUpload"
                        :on-error="uploadPicError"
                        :on-preview="handlePictureCardPreview"
                    >
                        <i class="el-icon-plus"></i>
                        <!-- <img
                v-if="confirForm.payImageUrl"
                :src="confirForm.payImageUrl"
                class="el-upload-list__item-thumbnail"
              />
              <i v-else slot="default" class="el-icon-plus"></i> -->
                    </el-upload>
                    <div class="el-upload__tip">
                        jpg、jpeg、png格式，大小5M以内
                    </div>
                </el-form-item>
                <el-form-item label="支付备注" prop="payNote">
                    <el-input v-model="confirForm.payNote"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="confirmationofPaymentDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="setPaystateData()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getAllAreaList, itsoRegisterList, queryDistrictPage, itsoRegisterApprove, itsoRegisterPayment, itsoRegisterDelMatched, itsoRegisterMatched, getSchoolListWithCity } from '@/http/api'
export default {
  data () {
    return {
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_schoolGroup: this.$userInfo.dict_schoolGroup(),
      dataList: [],
      dict_district: [],
      hideUploadAdd: false,
      imgFilesList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      upLoadPicUrl: this.$env.baseIP + 'upload/uploadFileAliOss',
      headerObj: {
        Authorization: localStorage.getItem('Authorization')
      },
      confirmationofPaymentDialogVisible: false,
      coverImageChange: false,
      confirForm: {
        payNote: ''
      },
      queryForm: {
        schoolAreaArray: [],
        season: this.$chnEngStatusCode.defaultSeason,
        status: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      areasData: [],
      matchDialogVisible: false,
      queryFormSchoolList: [],
      matchForm: {
        itsoRegisterId: '',
        schoolId: ''
      },
      confirFormRules: {
        payImageUrl: [{ required: true, message: '请选择', trigger: 'change' }],
        payNote: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      matchFormRules:
            {
              schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
            },
      dict_examine: this.$userInfo.dict_examine(),
      checkDialogVisible: false,
      checkForm: {
        id: null,
        userId: '',
        status: '',
        rejected: ''
      },
      checkFormRules: {
        rejected: [{ required: true, message: '请输入', trigger: 'blur' }],
        status: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.getDistrictList()
    this.getAllAreaList()
    this.getData()
  },
  methods: {
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getSchoolContactsData()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    async getData (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
      }
      if (this.queryForm.schoolAreaArray.length > 0) {
        this.queryForm.schoolAreaIds = this.queryForm.schoolAreaArray.join('/')
      }
      itsoRegisterList(this.queryForm).then((res) => {
        if (res.code === 200) {
          this.dataList = res.data.list
          this.total = res.data.total
        }
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getData()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getData()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryForm.schoolAreaIds = null
      this.getData()
    },
    showCheckDialog (data, type) {
      this.checkForm.id = data.id
      this.checkForm.userId = data.userId
      if (type === 'turnDown') {
        this.checkForm.status = '2'
      } else {
        this.checkForm.status = null
      }
      this.checkDialogVisible = true
    },
    // 显示匹配弹窗
    showMatchDialog (rowData) {
      this.matchForm.itsoRegisterId = rowData.id
      this.matchDialogVisible = true
    },
    // 显示确认缴费弹窗个
    confirmationofPayment (rowData) {
      this.confirForm.id = rowData.id
      this.confirmationofPaymentDialogVisible = true
    },
    // 审核窗口关闭
    checkDialogVisibleClose () {
      this.$refs.checkFormRef.resetFields()
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 限制图片大小
    beforeAvatarUpload (file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt5M
    },
    uploadPicSuccess (res) {
      console.log('uploadPicSuccess', res)
      if (res.code !== 200) {
        return this.$message.error(res.message)
      }
      if (res.data) {
        this.$set(this.confirForm, 'payImageUrl', res.data)
        this.hideUploadAdd = true
        this.$message.success('上传图片成功')
      }
    },
    uploadPicError (error) {
      console.log('uploadPicError', error)
    },
    // 确认修改支付状态
    setPaystateData () {
      if (this.$refs.uploadCoverImgRef.uploadFiles.length === 0) {
        return this.$message.warning('请先选择缴费凭证！')
      }
      this.$refs.confirFormRef.validate(valid => {
        if (!valid) return
        this.setUpPaySatet()
      })
    },
    // 修改支付状态请求
    setUpPaySatet () {
      itsoRegisterPayment(this.confirForm).then((res) => {
        this.confirmationofPaymentDialogVisible = false
        this.getData()
        return this.$message.success('修改成功！')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    showBigImageClicked (rowData) {

    },
    confirmationofDialogClose () {
      this.$set(this.confirForm, 'payImageUrl', null)
      this.imgFilesList = []
      this.hideUploadAdd = false
      this.$refs.confirFormRef.resetFields()
    },
    // 批量导出
    exportTable () {
      var downLoadUrl = this.$env.baseIP + 'itsoRegister/export'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    },
    // 保存学校匹配数据
    setMatchData () {
      this.$refs.matchFormRef.validate(async valid => {
        if (!valid) return
        itsoRegisterMatched(this.matchForm).then((res) => {
          if (res.code === 200) {
            this.$message.success('匹配成功')
            this.$refs.matchFormRef.resetFields()
            this.matchDialogVisible = false
            this.getData()
          }
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    showUnbindDialog (rowData) {
      this.$confirm('是否解除当前学校绑定信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.unbindData(rowData)
      }).catch(() => {
      })
    },
    // 解绑学校
    unbindData (rowData) {
      itsoRegisterDelMatched({ id: rowData.itsoRegisterJoin.id }).then((res) => {
        if (res.code === 200) {
          this.$message.success('解绑成功')
          this.getData()
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 跳转俱乐部详情
    goClubDetail (clubId) {
      this.$router.push(
        {
          path: '/team_management/team_manage_homeview/team_club_detailData',
          query: {
            clubId: clubId
          }
        }
      )
      // this.$router.push(
      //   {
      //     path: '/match_thing_manage/itso/club_detail',
      //     query: {
      //       clubId: data
      //     }
      //   }
      // )
    },
    // 审批
    setStatus () {
      this.$refs.checkFormRef.validate(async valid => {
        if (!valid) return
        itsoRegisterApprove(this.checkForm).then((res) => {
          this.$message.success('审核成功')
          this.checkDialogVisible = false
          this.getData()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (!value) return
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.queryFormSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    groupLevelFrmatter (cellValue) {
      var temp = '未分配'
      for (let index = 0; index < this.dict_schoolGroup.length; index++) {
        const element = this.dict_schoolGroup[index]
        if ((cellValue.groupLevel + '') === element.dictValue) {
          temp = element.dictLabel
        }
      }
      return temp
    }
  }
}
</script>

  <style lang="less">
.hide .el-upload--picture-card {
    display: none !important;
}
</style>
